import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="よくあるご質問"
      description="株式会社アイテクスに関するよくあるご質問になります。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body has-text-centered">
        <div className="container">
          <h1 className="title">よくあるご質問</h1>
          <h2 className="subtitle">FAQ</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container is-max-desktop">
        <h3 className="title">企業情報</h3>
        <ul>
          <li className="faq">
            <p className="faq__question">会社を設立されたのはいつですか？</p>
            <p className="faq__answer">2016年01月に設立しました。</p>
          </li>
          <li className="faq">
            <p className="faq__question">どのような事業を展開していますか？</p>
            <p className="faq__answer">
              鹿児島でシステム開発を中心にWebサイト制作、ITに関するコンサルティング・運用支援・保守サービス等を行っております。詳しくは「
              <Link to="/company/">企業情報</Link>
              」のページをご覧ください
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">会社概要を教えてください</p>
            <p className="faq__answer">
              「<Link to="/company/">企業情報</Link>
              」のページをご覧ください
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">男女比は？</p>
            <p className="faq__answer">
              男性75%、女性25%です。（2021年11月時点）
            </p>
          </li>
        </ul>
        <h2 className="title mt-6">働き方</h2>
        <ul>
          <li className="faq">
            <p className="faq__question">リモートワークは可能ですか？</p>
            <p className="faq__answer">
              可能です。リモートワーク推奨をしているため、自宅での勤務可能です。
              <br />
              <strong>※出勤日あり（月の80％以上がリモート作業可能）</strong>
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">勤務体制について教えて下さい。</p>
            <p className="faq__answer">
              09:00〜18:00 （休憩1時間）もしくは10:00〜19:00 <br />
              （休憩1時間）のどちらかからお選びいただけます。
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">休日や休暇について教えて下さい。</p>
            <p className="faq__answer">
              土日祝日が休みです。休暇は年末年始休暇、夏季休暇、慶弔休暇、介護休暇、産休・育児休暇(実績あり)があり、自分の誕生日に休みを取得できるバースデー休暇制度、恋愛対象者に交際申込、もしくはプロポーズをする日に有給休暇を優先して取得する事ができる恋人づくり応援休暇制度があります。
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">
              応募したいのですが、必要なスキルや条件はありますか。
            </p>
            <p className="faq__answer">
              必須条件と歓迎要件があります。詳しくは「
              <Link to="/recruit/">採用情報</Link>」のページをご覧ください。
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">試用期間はありますか。</p>
            <p className="faq__answer">
              入社日より6ヵ月間の試用期間を設けています。
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">
              シェアオフィス『Switch』に通勤する場合、駐車場はありますか？
            </p>
            <p className="faq__answer">
              会社の駐車場は無いですが、周辺にコインパーキングが多数ございます。
              また、天文館になりますので公共機関での通勤に便利です。
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">働く服装に決まりがありますか？</p>
            <p className="faq__answer">
              規定の服装はございませんが、作業用のジャケットを用意しております。
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">転勤はありますか？</p>
            <p className="faq__answer">転勤はありません。</p>
          </li>
        </ul>
        <h2 className="title mt-6">その他</h2>
        <ul>
          <li className="faq">
            <p className="faq__question">
              育児や子育てをサポートする制度はありますか？
            </p>
            <p className="faq__answer">
              リモートワークを推奨すると共に、産休・育休制度があります。男性社員の取得も可能です。
              <br />
              また、子どもの体調やご家族の事情に合わせて勤務時間を調整できるよう柔軟に対応しております。
              <br />
              （女性で1人、男性で1人の取得実績がございます）
            </p>
          </li>
          <li className="faq">
            <p className="faq__question">
              ITに関する相談やお問い合わせはどうしたら良いですか？
            </p>
            <p className="faq__answer">
              ITに関する相談に関しましては「
              <Link to="/contact/">お問い合わせ</Link>
              」ページから承っております。お気軽にお問い合わせください。
            </p>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
)
export default IndexPage
